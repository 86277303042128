import { useUrlState } from '@village/tools';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RecipientEntry } from './components/recipient-entry';
import { SelectRecipientLoading } from './select-recipient-loading';
import * as Styled from './styles';
import { useProviders } from 'data/hooks/use-providers';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { RoundedContainer } from 'pages/page-layouts';

const SelectRecipientContent: FC = () => {
    const { data, isLoading, isFetching } = useProviders();
    const { search: queryParams } = useLocation();
    const navigate = useNavigate();
    const [medicationName] = useUrlState('medicationName');
    const [redirectTo] = useUrlState('redirect-to');
    const { setMessagingFields } = useMessaging();

    useNativeControls({
        ipcEvents: ['showControls'],
        source: 'messaging',
    });

    useEffect(() => {
        if (!isLoading && !isFetching && data && data.results.length === 0) {
            navigate(`/compose-message${queryParams}`, { replace: true });
        }
    }, [isLoading, isFetching, data, navigate, queryParams]);

    useEffect(() => {
        if (medicationName) {
            setMessagingFields({
                medicationName,
                messageInitiatedFrom: 'native',
                messageType: 'PATIENTCASE_REFILL',
            });
        }
    }, [medicationName, setMessagingFields]);

    useEffect(() => {
        if (redirectTo === 'native') {
            setMessagingFields({
                messageInitiatedFrom: 'native',
            });
        }
    }, [redirectTo, setMessagingFields]);

    return (
        <RoundedContainer headerContent="Select a provider to message">
            <Styled.SuggestedContainer>SUGGESTED PROVIDERS</Styled.SuggestedContainer>
            <Styled.OptionsContainer>
                {isLoading ? (
                    <SelectRecipientLoading />
                ) : (
                    data?.results.map((provider) => <RecipientEntry key={provider.providerid} provider={provider} />)
                )}
            </Styled.OptionsContainer>
        </RoundedContainer>
    );
};

export { SelectRecipientContent };
