import { Collapse } from '@material-ui/core';
import { Button } from '@village/ui';
import type { FC } from 'react';
import { useState, Fragment, useCallback, useEffect, useMemo, createRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { useMessageThreadMarkRead } from 'data/hooks/use-message-thread-mutation';
import { useProviders } from 'data/hooks/use-providers';
import { useFeatureFlags } from 'hooks';
import { useNativeControls } from 'hooks/use-native-controls';
import { useNativeIpc } from 'hooks/use-native-ipc';
import { Countly } from 'modules/countly';
import { MessageCard } from 'pages/message-thread/components/message-card';
import type { MessageThread } from 'types';

type ParameterType = 'folder';
interface MessageListProps {
    readonly messageThread: MessageThread;
}
const maxUncollapsedMessages = 5;

const MessageList: FC<MessageListProps> = ({ messageThread }) => {
    const { data: patientProvidersData } = useProviders();
    const patientProviders = patientProvidersData?.results;
    const navigate = useNavigate();
    const { hasFeature } = useFeatureFlags();
    const { folder } = useParams<ParameterType>();
    const scrollRef = createRef<HTMLDivElement>();
    const [expanded, setExpanded] = useState(false);
    const { source, message_thread_id, messages, subject } = messageThread;
    const collapsibleMessageThreads = hasFeature('collapsibleMessageThreads');

    const messageThreadSource = source ?? 'provider';

    const { mutateAsync, isIdle, data } = useMessageThreadMarkRead();

    useEffect(() => {
        if (folder === 'sent') return; // Prevent marking sent messages as unread

        const mostRecentMessage = 0;
        const isUnread = messages[mostRecentMessage].unread;

        if (isUnread && isIdle) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            mutateAsync({ messageThreadId: message_thread_id, messageThreadSource }).then(() => {
                Countly.addEvent({
                    key: 'markMessageThreadRead',
                    segmentation: {
                        messageThreadId: message_thread_id,
                        messagingVersion: 2,
                        source: 'messaging',
                    },
                });
            });
        }
    }, [mutateAsync, isIdle, data, message_thread_id, folder, messageThreadSource, messages]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [scrollRef]);

    const navigateToReplyScreen = useCallback(() => {
        if (folder) {
            navigate(`/message-reply/${message_thread_id}/${folder}/${messageThreadSource}`);
        }
    }, [folder, navigate, message_thread_id, messageThreadSource]);

    useNativeIpc('composeMessageReply', navigateToReplyScreen);

    // hideControls is here for backwards compatibility
    useNativeControls({
        ipcEvents: hasFeature('webNavHeader')
            ? ['hideControls', 'showBackButton', 'showReplyMessage', 'hideHeader']
            : ['hideControls', 'showBackButton', 'showReplyMessage', 'showHeader'],
        replyButtonFunction: navigateToReplyScreen,
        source: 'messaging',
    });

    const getProvider = useMemo(() => {
        const rootMessageIndex = messages.length - 1;
        const rootMessage = messages[rootMessageIndex];
        if (rootMessage.to?.providerid) return rootMessage.to;
        if (rootMessage.from?.providerid) return rootMessage.from;
        return null;
    }, [messages]);

    const messagesCards = useMemo(
        () =>
            messages
                .map((message, index) => (
                    <MessageCard
                        key={message.timestamp}
                        isMostRecentMessage={index === 0} // 0 here is the most recent message index
                        isUnread={message.unread}
                        message={message}
                        navigateToReplyScreen={collapsibleMessageThreads ? undefined : navigateToReplyScreen}
                        patientProviders={patientProviders}
                        provider={getProvider}
                    />
                ))
                .reverse(),
        [collapsibleMessageThreads, getProvider, messages, navigateToReplyScreen, patientProviders]
    );

    const showAllMessages = (): void => setExpanded((current) => !current);

    const firstMessage = messages[messages.length - 1];
    const lastMessage = messages[0];
    const collapsedMessages = useMemo(
        () =>
            messages.filter(
                (message) =>
                    !message.unread && message.timestamp !== firstMessage.timestamp && message.timestamp !== lastMessage.timestamp
            ),
        [firstMessage.timestamp, messages, lastMessage.timestamp]
    );
    const unreadMessages = useMemo(() => messages.filter((message) => message.unread), [messages]);
    const unreadMessagesOrLast = useMemo(
        () => (unreadMessages.length > 0 ? unreadMessages : [lastMessage]),
        [lastMessage, unreadMessages]
    );

    const collapsedMessageCards = useMemo(
        () =>
            collapsedMessages.length > 0 ? (
                <Fragment>
                    <MessageCard
                        key={firstMessage.timestamp}
                        isMostRecentMessage={false}
                        isUnread={firstMessage.unread}
                        message={firstMessage}
                        navigateToReplyScreen={collapsibleMessageThreads ? undefined : navigateToReplyScreen}
                        patientProviders={patientProviders}
                        provider={getProvider}
                    />
                    <Collapse in={!expanded} timeout="auto" unmountOnExit={true}>
                        <Styled.CollapsedCards data-testid="test_expand_thread" onClick={showAllMessages}>
                            Tap to open {collapsedMessages.length} more messages
                            <Styled.ChevronDown name="chevron-down" size={0.75} />
                        </Styled.CollapsedCards>
                    </Collapse>
                    <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                        {collapsedMessages
                            .map((message) => (
                                <MessageCard
                                    key={message.timestamp}
                                    isMostRecentMessage={false}
                                    isUnread={message.unread}
                                    message={message}
                                    navigateToReplyScreen={collapsibleMessageThreads ? undefined : navigateToReplyScreen}
                                    patientProviders={patientProviders}
                                    provider={getProvider}
                                />
                            ))
                            .reverse()}
                    </Collapse>
                    {unreadMessagesOrLast
                        .map((message, index) => (
                            <MessageCard
                                key={message.timestamp}
                                isMostRecentMessage={index === 0} // 0 here is the most recent message index
                                isUnread={message.unread}
                                message={message}
                                navigateToReplyScreen={collapsibleMessageThreads ? undefined : navigateToReplyScreen}
                                patientProviders={patientProviders}
                                provider={getProvider}
                            />
                        ))
                        .reverse()}
                </Fragment>
            ) : null,
        [
            collapsedMessages,
            firstMessage,
            collapsibleMessageThreads,
            navigateToReplyScreen,
            patientProviders,
            getProvider,
            expanded,
            unreadMessagesOrLast,
        ]
    );

    const viewTestResults = useCallback(() => {
        // TODO: Should we ignore cache here if the origin was notification?
        navigate(`/individual-test-result/${source}/${message_thread_id}?hideMessageButton=true`);
    }, [navigate, message_thread_id, source]);

    return (
        <Styled.MessagesContainer>
            <Styled.MessageSubject>{subject}</Styled.MessageSubject>
            <Styled.ScrollableContainer>
                {messages.length >= maxUncollapsedMessages ? (
                    collapsedMessageCards
                ) : (
                    <Styled.Messages>{messagesCards}</Styled.Messages>
                )}
                {source === 'labresult' && (
                    <Styled.LabButton>
                        <Button data-testid="view-labs-button" fullWidth={true} onClick={viewTestResults}>
                            View lab results
                        </Button>
                    </Styled.LabButton>
                )}
                {source === 'imagingresult' && (
                    <Styled.LabButton>
                        <Button data-testid="view-labs-button" fullWidth={true} onClick={viewTestResults}>
                            View imaging results
                        </Button>
                    </Styled.LabButton>
                )}
                <div ref={scrollRef} />
            </Styled.ScrollableContainer>
        </Styled.MessagesContainer>
    );
};

export { MessageList };
