import { useCallback, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MedicationDetailsLoading } from './medication-details-loading';
import * as Styled from './styles';
import { ChatWithUs } from 'components/chat-with-us';
import { Icon } from 'components/icon';
import { useMedications } from 'data/hooks/use-medication-query';
import { useFeatureFlags } from 'hooks';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { DrawerIPCBody } from 'modules/ipc/types';
import type { Medication } from 'types';

type ParametersTypes = 'medicationId';

const MedicationDetailsContent: FC = () => {
    const { hasFeature } = useFeatureFlags();
    const pharmacyEnabled = hasFeature('preferredPharmacy.medicationDetails');
    const { data: medicationData, isLoading } = useMedications();
    const { setMessagingFields } = useMessaging();
    const { pathname } = useLocation();

    const navigate = useNavigate();
    const { medicationId } = useParams<ParametersTypes>();

    // We don't have a dedicated api endpoint to fetch single medication details.
    const medication = useMemo(
        () => medicationData?.results.find((item) => item.medication_entry_id === medicationId),
        [medicationData, medicationId]
    );

    const { pharmacy_facility } = medication ?? ({} as Medication);

    const transferPrescription = useCallback(() => {
        sendToNative('openNativeDrawer', 'health-records', {
            bodyText:
                'To expedite the transfer of your medication to another pharmacy, simply reach out to the new pharmacy and share the address and phone number of your current pharmacy.',
            buttonText: 'Okay',
            icon: 'house',
            titleText: 'Transfer prescription',
        } as DrawerIPCBody);
    }, []);

    const chatContainer = useMemo(() => {
        if (hasFeature('chatMedicationsRefill')) {
            return null;
        }
        if (hasFeature('chatMedications')) {
            return (
                <Styled.ChatContainer>
                    <ChatWithUs />
                </Styled.ChatContainer>
            );
        }
        return null;
    }, [hasFeature]);

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: '',
    });

    useEffect(() => {
        if (medication) {
            Countly.addEvent({
                key: 'viewIndividualMedication',
                segmentation: {
                    medicationName:
                        medication?.medication_name &&
                        (medication.medication_name === 'Refill another prescription'
                            ? medication.medication_name
                            : 'Refill existing prescription'),
                },
            });
        }
    }, [medicationId, medication]);

    const viewPharmacyAction = (): void => {
        navigate(`/preferred-pharmacy?medicationId=${medicationId ?? ''}`);
    };

    const handleClick = (): void => {
        Countly.addEvent({
            key: 'composeInitiate',
            segmentation: {
                initiatedSource: 'healthrecords_Medication',
                source: 'healthrecords_villagemedical',
            },
        });

        setMessagingFields({
            medicationName: medication?.medication_name,
            messageType: 'PATIENTCASE_REFILL',
        });

        navigate(`/select-recipient?redirect-to=${pathname}`);
    };

    if (isLoading) {
        return <MedicationDetailsLoading />;
    }

    if (!medication) {
        // TODO: Replace this with a proper 404 message component
        return <div>Medication not found</div>;
    }

    const { instructions, medication_name, quantity, quantity_unit, medication_type } = medication;

    return (
        <Styled.Container>
            {chatContainer}
            <Styled.MedicationName> {medication_name}</Styled.MedicationName>
            {medication_type === 'HISTORICAL' ? (
                <Styled.ChipContainer data-testid="chip-container">
                    <Styled.InactiveChip label="Inactive" />
                </Styled.ChipContainer>
            ) : null}
            <Styled.DetailsContainer>
                <Styled.FlexRow>
                    <Styled.ListIcon size="md" />
                    <Styled.FlexColumn>
                        <Styled.BoldText>Instructions</Styled.BoldText>
                        <Styled.Text>{instructions}</Styled.Text>
                    </Styled.FlexColumn>
                </Styled.FlexRow>
                <Styled.FlexRow>
                    <Styled.CircleInfoIcon size="md" />
                    <Styled.FlexColumn>
                        <Styled.BoldText>Quantity</Styled.BoldText>
                        <Styled.Text>
                            {quantity} {quantity_unit}
                        </Styled.Text>
                    </Styled.FlexColumn>
                </Styled.FlexRow>
                {pharmacyEnabled && pharmacy_facility != null ? (
                    <Styled.FlexRow>
                        <Styled.Building size="md" />
                        <Styled.FlexColumn bottomPadding={false}>
                            <div onClick={viewPharmacyAction} role="none">
                                <Styled.BoldText>Pharmacy</Styled.BoldText>
                                <Styled.ChevronContanier>
                                    <Styled.Text>
                                        {pharmacy_facility.name}
                                        <br />
                                        {pharmacy_facility.address}
                                        <br /> {pharmacy_facility.city}, {pharmacy_facility.state}
                                    </Styled.Text>
                                    <Icon name="chevron-right" size={1} />
                                </Styled.ChevronContanier>
                            </div>
                            <Styled.DirectionsText onClick={transferPrescription}>
                                Need to transfer prescription?
                            </Styled.DirectionsText>
                        </Styled.FlexColumn>
                    </Styled.FlexRow>
                ) : null}
                <Styled.FlexRow>
                    <Styled.Medications size="md" />
                    <Styled.FlexLastColumn>
                        <Styled.BoldText>Refills</Styled.BoldText>
                        <Styled.Text>
                            You can request a refill by simply calling your pharmacy. To renew your prescription, you can send a
                            request by tapping the “Request prescription renewal” button below.
                        </Styled.Text>
                    </Styled.FlexLastColumn>
                </Styled.FlexRow>
            </Styled.DetailsContainer>
            <Styled.ButtonContainer>
                <Styled.Button data-testid="medication-refill-button" fullWidth={true} onClick={handleClick}>
                    Request prescription renewal
                </Styled.Button>
            </Styled.ButtonContainer>
        </Styled.Container>
    );
};

export { MedicationDetailsContent };
