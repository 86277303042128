import { ButtonCard } from '@village/ui';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { Countly } from 'modules/countly';
import type { ImagingResultWithMessageData } from 'types';
import { formatDate } from 'utils';

interface Props {
    readonly imagingResult: ImagingResultWithMessageData;
}

const ImagingResultEntryCard: React.FC<Props> = ({ imagingResult }) => {
    const { test_result_name, imagingresultid, last_message_timestamp, createddatetime } = imagingResult;
    const imaging_result_date = createddatetime ? formatDate(createddatetime, 'fullDate') : null;

    const navigate = useNavigate();

    const handleMessageTypeClicked = useCallback((): void => {
        if (last_message_timestamp) {
            navigate(`/message/${imagingresultid}/inbox/imagingresult`);
        } else {
            navigate(`/test-result-messaging-preview/imagingresult/${imagingresultid}?hideMessageButton=true`);
        }
    }, [imagingresultid, last_message_timestamp, navigate]);

    useEffect(() => {
        Countly.addEvent({
            key: 'viewImagingResultPreview',
            segmentation: {
                flow: 'testresults',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, []);

    return (
        <Styled.Container>
            <ButtonCard onClick={handleMessageTypeClicked} primaryText={test_result_name}>
                {imaging_result_date}
                {last_message_timestamp ? (
                    <Styled.LastMessageContainer>
                        Last message sent on {formatDate(last_message_timestamp * 1000, 'fullDate') ?? ''}
                    </Styled.LastMessageContainer>
                ) : null}
            </ButtonCard>
        </Styled.Container>
    );
};

export { ImagingResultEntryCard };
