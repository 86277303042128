import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFeatureFlags } from './use-feature-flags';
import { useNativeIpc } from './use-native-ipc';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';

const useNativeRedirect = (): void => {
    const navigate = useNavigate();
    const { hasFeature } = useFeatureFlags();

    const startNewMessageFlow = useCallback(() => {
        Countly.addEvent({
            key: 'composeInitiate',
            segmentation: {
                initiatedSource: 'getCare',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
        if (!window.location.pathname.includes('/select-message-type')) {
            navigate('/select-message-type');
            if (!hasFeature('webNavHeader')) {
                sendToNative('showHeader', 'messaging');
            }
        }
    }, [hasFeature, navigate]);

    useNativeIpc('getCareComposeMessage', startNewMessageFlow);
};

export { useNativeRedirect };
