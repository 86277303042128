import { useUrlState } from '@village/tools';
import { Button } from '@village/ui';
import type { FC } from 'react';
import { useCallback, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Styled from './styles';
import { useIndividualTestResult } from 'data/hooks/use-individual-test-result';
import { useProviders } from 'data/hooks/use-providers';
import { useFeatureFlags } from 'hooks';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { RoundedContainer } from 'pages/page-layouts';
import { TestResultContent } from 'pages/test-results/test-result/test-result-page';
import type { TestResultParametersTypes } from 'types';

const TestResultPreview: FC = () => {
    const navigate = useNavigate();
    const { setMessagingFields } = useMessaging();
    const { hasFeature } = useFeatureFlags();

    const { testResultId, testResultType } = useParams<TestResultParametersTypes>();
    const [hideControls] = useUrlState('hideControls');
    const testResultsEnabled = hasFeature('imagingResultsSendMessage');
    const { testResult, isFetching: isLoadingTestResult } = useIndividualTestResult({
        testResultId: Number(testResultId),
        testResultType,
    });
    const { data: providerData, isLoading: providerDataLoading } = useProviders();
    const selectClick = useCallback(() => {
        setMessagingFields({
            messageSubject: `${testResult?.testResultName ?? ''} ${testResult?.testResultId ?? ''}`,
            messageType: testResult?.messageTypeKey,
            provider: providerData?.results.find(({ providerid }) => providerid === testResult?.providerid),
            testResult,
        });
        navigate(`/compose-message`);
    }, [setMessagingFields, testResult, providerData?.results, navigate]);

    // hideControls is here because of backwards compatibility
    useNativeControls({
        ipcEvents: hideControls ? ['hideControls', 'showBackButton'] : ['hideControls', 'showControls'],
        source: 'messaging',
    });

    return (
        <RoundedContainer headerContent={<Fragment>{testResultsEnabled ? 'Test' : 'Lab'} preview</Fragment>}>
            <Styled.InnerContainer>
                <TestResultContent testResultId={testResultId} testResultType={testResultType} />
            </Styled.InnerContainer>
            {!hideControls && (
                <Styled.Footer>
                    <Button
                        data-testid="send-labs-button"
                        disabled={isLoadingTestResult || providerDataLoading}
                        fullWidth={true}
                        onClick={selectClick}
                    >
                        Select this {testResultsEnabled ? 'test' : 'lab'}
                    </Button>
                </Styled.Footer>
            )}
        </RoundedContainer>
    );
};

export { TestResultPreview };
