import { satisfies } from 'semver';

import { appConfig } from 'modules/config';
import type { AppConfig, FeatureFlag } from 'types';

type FeatureFlagsByVersion = Record<FeatureFlag, Partial<Record<AppConfig['platform'], readonly string[]>>>;
type FeatureFlags = Record<FeatureFlag, Record<AppConfig['platform'], boolean>>;
type FeatureFlagBooleans = Record<FeatureFlag, boolean>;
/**
 * Default feature flags in case the `onInit` event was not received or not available (Native apps < 2.2.0 or Web).
 */
const defaultFeatureFlags: FeatureFlags = {
    careSummary: { android: false, ios: false, web: true },
    chatLabs: { android: false, ios: false, web: true },
    chatMedications: { android: false, ios: false, web: false },
    chatMedicationsRefill: { android: false, ios: false, web: false },
    chatMessaging: { android: false, ios: false, web: true },
    collapsibleMessageThreads: { android: false, ios: false, web: true },
    fontSizeScale: { android: false, ios: false, web: true },
    imagingResultsSendMessage: { android: false, ios: false, web: true },
    labsSendMessage: { android: false, ios: false, web: true },
    'messaging.allTestResults': { android: false, ios: false, web: true },
    'messaging.backAfterReply': { android: false, ios: false, web: true },
    'preferredPharmacy.medicationDetails': { android: false, ios: false, web: true },
    'preferredPharmacy.medicationList': { android: false, ios: false, web: true },
    unifiedWebview: { android: false, ios: false, web: true },
    verifyIdentity: { android: false, ios: false, web: true },
    webNavHeader: { android: false, ios: false, web: true },
};

/**
 * The native app version should at least satisfy one of the versions defined for each feature.
 * Learn more about versions precedence here: https://semver.org/
 *
 * Note: web will only use the defaultFeatureFlags declared above.
 */
const initialFeatureFlags: FeatureFlagsByVersion = {
    careSummary: { android: ['>=2.6.2'], ios: ['>=2.6.2'] },
    chatLabs: { android: ['>=2.2.0'], ios: ['>=2.2.0'] },
    chatMedications: { android: ['>=2.2.0'], ios: ['>=2.2.0'] },
    chatMedicationsRefill: { android: [], ios: [] },
    chatMessaging: { android: [], ios: [] },
    collapsibleMessageThreads: { android: ['>=2.7.3'], ios: ['>=2.7.3'] }, // follows the addition of the native "Reply" button in message threads
    fontSizeScale: { android: [], ios: ['>=2.7.6'] },
    imagingResultsSendMessage: { android: ['>=2.7.12'], ios: ['>=2.7.12'] }, // follows the enablement of test results in native AND the unified webview work
    labsSendMessage: { android: ['>=2.7.0'], ios: ['>=2.7.0'] }, // follows the addition of unified webview for native
    'messaging.allTestResults': { android: ['>=2.7.10'], ios: ['>=2.7.10'] },
    'messaging.backAfterReply': { android: ['<=2.6.3'], ios: ['<=2.6.2'] },
    'preferredPharmacy.medicationDetails': { android: ['>=2.7.10'], ios: ['>=2.7.10'] },
    'preferredPharmacy.medicationList': { android: ['>=2.7.10'], ios: ['>=2.7.10'] },
    unifiedWebview: { android: ['>=2.7.0'], ios: ['>=2.7.0'] },
    verifyIdentity: { android: [], ios: [] },
    webNavHeader: { android: ['>=2.7.12'], ios: ['>=2.7.12'] },
};

const calculateFeatureFlags = (featureFlagsByV: FeatureFlagsByVersion, nativeAppVersion: string): FeatureFlagBooleans =>
    Object.assign(
        {},
        ...Object.keys(featureFlagsByV).map((k) => {
            const versions = featureFlagsByV[k as FeatureFlag][appConfig.platform];
            return {
                [k]: versions ? versions.length > 0 && satisfies(nativeAppVersion, versions.join('||')) : false,
            };
        })
    );

const calculateDefaultFeatureFlags = (featureFlagsByPlatform: FeatureFlags): FeatureFlagBooleans =>
    Object.assign(
        {},
        ...Object.keys(featureFlagsByPlatform).map((k) => ({
            [k]: featureFlagsByPlatform[k as FeatureFlag][appConfig.platform],
        }))
    );

export type { FeatureFlagBooleans };
export { initialFeatureFlags, defaultFeatureFlags, calculateFeatureFlags, calculateDefaultFeatureFlags };
