import { useUrlState } from '@village/tools';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { TopNav } from './components/top-nav';
import { MessageList } from './message-list';
import * as Styled from './styles';
import type { MessagingPages } from './types';
import { useUnreadMessageCount } from 'data/hooks/use-message-unread-count';
import { useFeatureFlags } from 'hooks';
import { useNativeControls } from 'hooks/use-native-controls';
import { useNavHeader } from 'hooks/use-nav';
import { sendToNative } from 'modules/ipc';
import { useSourceByFeatureFlag } from 'utils/test-results';

const Messaging: FC = () => {
    const [activeTab, setActiveTab] = useState<MessagingPages>('inbox');
    const [origin] = useUrlState('origin');
    const ignoreCache = origin === 'notification';
    const { data: unreadMessagesData, refetch: refetchUnreadCount } = useUnreadMessageCount(
        useSourceByFeatureFlag(['imagingResultsSendMessage', 'labsSendMessage'], ['provider']),
        ignoreCache
    );
    const { resetHistoryState, setShowingHeaderNav } = useNavHeader();
    const { hasFeature } = useFeatureFlags();

    // hideControls is here for backwards compatibility
    useNativeControls({
        ipcEvents: ['hideControls', 'showBottomNavBar', 'hideHeader'],
        source: 'messaging',
    });

    useEffect(() => {
        resetHistoryState();
    }, [resetHistoryState]);

    useEffect(
        () => () => {
            if (!hasFeature('webNavHeader')) {
                sendToNative('showHeader', 'messaging');
            } else {
                sendToNative('hideHeader', 'messaging');
            }
        },
        [hasFeature]
    );

    useEffect(() => {
        setShowingHeaderNav(false);

        return () => setShowingHeaderNav(true);
    }, [setShowingHeaderNav]);

    return (
        <Styled.Container>
            <TopNav
                activeTab={activeTab}
                refetchUnreadCount={refetchUnreadCount}
                setActiveTab={setActiveTab}
                unreadCount={unreadMessagesData}
            />
            <Styled.ContentContainer>
                {activeTab === 'inbox' ? (
                    <MessageList
                        folder="inbox"
                        ignoreCache={ignoreCache}
                        markUnread={true}
                        refetchUnreadCount={refetchUnreadCount}
                    />
                ) : null}
                {activeTab === 'sent' ? (
                    <MessageList
                        folder="sent"
                        ignoreCache={ignoreCache}
                        markUnread={false}
                        refetchUnreadCount={refetchUnreadCount}
                    />
                ) : null}
            </Styled.ContentContainer>
        </Styled.Container>
    );
};

export { Messaging };
