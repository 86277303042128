import { memo, useEffect } from 'react';
import type { FC, ReactNode } from 'react';

import * as Styled from './styles';
import { NavHeader } from '../nav-header';
import { useFeatureFlags } from 'hooks';
import { useDebugging } from 'hooks/use-debugging';
import { useNavHeader } from 'hooks/use-nav';
import { DebuggingPage } from 'pages/debugging';

interface Props {
    readonly title: string;
    readonly placeholders: {
        readonly main: ReactNode;
    };
}

const MainMasterPage: FC<Props> = memo(({ placeholders, title }) => {
    useEffect(() => {
        // eslint-disable-next-line functional/immutable-data
        document.title = `Village+ Health Records | ${title}`;
    }, [title]);
    const { hasFeature } = useFeatureFlags();
    const { showingHeaderNav } = useNavHeader();
    const { debugEnabled } = useDebugging();
    const mainPage = (
        <Styled.Main>
            <NavHeader showingHeader={hasFeature('webNavHeader') && showingHeaderNav}>{placeholders.main}</NavHeader>
        </Styled.Main>
    );

    return debugEnabled ? <DebuggingPage>{mainPage}</DebuggingPage> : mainPage;
});

export { MainMasterPage };
